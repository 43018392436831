<template>
    <section class="custom-scroll overflow-auto pr-4" style="height:calc(100vh - 180px)">
        <div class="row mx-0">
            <div class="col-auto px-2 bg-white br-12 py-2 text-center">
                <img :src="cedis.imagen" width="160" height="160" class="br-12 obj-cover border" />
                <div class="row mx-0 mt-4">
                    <div class="col pl-0">
                        <p class="f-600 my-2 text-general-red"> Cedis {{ cedis.estado ? 'Activo' : 'Inactivo' }} </p>
                    </div>
                    <div class="col-auto px-0 ml-auto">
                        <div class="btn-action border cr-pointer d-middle-center br-8" @click="eliminar">
                            <i class="icon-delete-outline f-22 text-general" />
                        </div>
                    </div>
                    <div class="col-auto px-0 mx-2">
                        <div class="btn-action border cr-pointer d-middle-center br-8" @click="editarCedis">
                            <i class="icon-pencil-outline f-22 text-general" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col pr-0 pl-2 ml-3">
                <div class="row mx-0 my-1 bg-white br-12 py-3 mb-2">
                    <div class="col border-right text-general">
                        <p class="text-center f-600 f-30">
                            {{ agregarSeparadoresNumero(cedis.valores.productos) }}
                        </p>
                        <div class="row mx-0 justify-center align-items-center">
                            <i class="icon-package-variant-closed text-general f-20" />
                            <span class="text-general f-400">
                                Productos
                            </span>
                        </div>
                    </div>
                    <div class="col text-general border-right">
                        <p class="text-center f-600 f-30">
                            {{ agregarSeparadoresNumero(cedis.valores.unidades) }}
                        </p>
                        <div class="row mx-0 justify-center align-items-center">
                            <i class="icon-format-list-checks text-general f-20" />
                            <span class="text-general f-400">
                                Unidades
                            </span>
                        </div>
                    </div>
                    <div class="col text-general">
                        <p class="text-center f-600 f-30">
                            {{ separadorNumero(cedis.valores.valores) }} {{ moneda.sigla }}
                        </p>
                        <div class="row mx-0 justify-center align-items-center">
                            <i class="icon-currency-usd-circle text-general f-19" />
                            <span class="text-general f-400">
                                Valor del stock
                            </span>
                        </div>
                    </div>
                </div>
                <!-- Separador -->
                <div class="row mx-0 my-2" />
                <!-- Separador -->
                <div class="row mx-0">
                    <div class="col-3 px-3 py-2 bg-white br-12">
                        <div class="row mx-0">
                            <img :src="cedis.responsable.imagen" width="44" height="44" class="rounded-circle obj-cover border" />
                            <div class="col px-2">
                                <p class="f-600 my-2 text-general"> {{ cedis.responsable.nombre }} </p>
                                <p class="my-1 f-14 text-general"> Responsable </p>
                            </div>
                        </div>
                        <p>
                            <i class="icon-phone f-22 text-general ml-2" />
                            <span class="text-general f-15">
                                {{ cedis.telefono }}
                            </span>
                        </p>
                    </div>
                    <div class="col px-2 ml-3 bg-white br-12 pb-2">
                        <p class="text-general f-17 f-600 pl-3 mt-2">
                            Condiciones
                        </p>
                        <div class="row mx-0 align-items-center py-2">
                            <div class="btn-cedis-stock f-500 px-2 cr-pointer col-xl-4 col-lg-5 col-md-5 col-sm-12 col-12 px-0 border" @click="verInventario">
                                <i class="icon-cedis f-18 text-general" />
                                <span class="text-general f-13 f-400 ml-1">{{ cedis.id_cedis_stock ? 'Almacena inventario en Cedis diferente' : 'Stock Propio' }}</span>
                            </div>
                            <div class="btn-cedis-stock f-500 px-2 cr-pointer col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 ml-3 px-0 border" @click="usarCatalogo">
                                <i class="icon-book-open-variant f-18 text-general" />
                                <span class="text-general f-13 f-400 ml-1">{{ cedis.id_cedis_catalogo ? 'Usa catálogo diferente' : 'Catálogo Propio' }}</span>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-12 ml-2 px-0">
                                <i class="icon-impuestos f-18 text-general" />
                                <span class="text-general f-15 ml-1">
                                    {{ cedis.impuestos ? 'Aplica impuestos' : 'No aplica impuestos' }}
                                </span>
                            </div>
                            <div class="px-2 col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <div v-if="cedis.operacion_logistica === 1">
                                    <i class="icon-truck f-18 text-general" />
                                    <span class="text-general f-15">
                                        Operador Logístico "Interno"
                                    </span>
                                </div>
                                <div v-if="cedis.operacion_logistica === 2">
                                    <i class="icon-truck f-18 text-general" />
                                    <span class="text-general f-15">
                                        Operador Logístico "Externo"
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="col-auto ml-3 px-0">
                                <div v-if="cedis.operacion_logistica === 2">
                                    <i class="icon-truck f-18 text-general" />
                                    <span class="text-general f-15">
                                        Operador Logístico "Externo"
                                    </span>
                                </div>
                            </div> -->
                        </div>
                        <p class="text-general f-15 px-1">
                            <i class="icon-package-variant-closed f-22 ml-1 text-general" />
                            <span class="text-general f-15">Productos que no se pueden vender sin stock:</span>
                            <span v-if="cedis.stock_agotado === 1"> Con productos disponibles</span><span v-else> Productos reservados</span>
                        </p>
                        <p class="text-general f-15 px-1 d-middle">
                            <i class="icon-box f-22 ml-1 text-general" />
                            <span class="text-general f-15">Còdigo de almacen: </span>
							<span class="ml-2" v-if="cedis.codigo_almacen && !editCode"> {{ cedis.codigo_almacen }} </span>
							<el-input v-if="editCode" v-model="cedis.codigo_almacen" class="w-20 ml-2" size="small" maxlength="10" show-word-limit placeholder="Còdigo" @change="editCodeAlmacen" />
							<el-tooltip placement="bottom" content="Cambiar código" effect="light">
                        	    <i v-if="!editCode" class="btn-action icon-pencil-outline f-20 cr-pointer ml-1 cr-pointer br-8 text-general ml-2" @click="editCode = true" />
                            </el-tooltip>
                        </p>
                    </div>
                </div>
            </div>
            <!-- Separador -->
            <div class="col-12 mx-0 my-2" />
            <div class="col-12 bg-white br-8 py-3" style="min-height:117px;">
                <p class="text-general f-17 f-600">
                    Descripción
                </p>
                <p class="mt-2 text-general">
                    {{ cedis.descripcion }}
                </p>
            </div>
            <!-- Separador -->
            <!-- Separador -->
            <div class="col-12 mx-0 my-2" />
            <div class="col-12 bg-white f-17 br-8 py-3">
                <p class="text-general f-17 f-600">
                    Horario laboral
                </p>
                <template v-if="cedis.franjas.parametros.horarios_uso">
                    <div class="pl-5 mb-5">
                        <div class="row mx 0 mt-4 align-items-center mb-2">
                            <p class="col-auto mr-5 px-0 f-17 f-500 text-general">
                                Mensaje de entrega <small class="f-13 text-general2 ml-2" style="font-style: italic;">Este mensaje aparece en el Checkout del vendedor y del cliente</small>
                            </p>
                        </div>
                        <div class="row mx 0 align-items-center mb-5">
                            <p class="col-auto mr-5 px-0 f-15 text-general">
                                {{ cedis.franjas.parametros.horarios_texto }}
                            </p>
                        </div>
                        <div class="row mx 0 mt-3 align-items-center mb-5">
                            <p class="col-auto mr-5 px-0 f-17 f-500 text-general">
                                Horario de los cedis
                            </p>
                            <div class="col-auto d-middle">
                                <span class="text-general f-600 f-20 mr-2">
                                    {{ cedis.franjas.parametros != null ? cedis.franjas.parametros.franja_programacion_cedis : '-' }}
                                </span>
                                <p class="col f-14 text-general f-300">
                                    Horas mínimo de <br /> programación del pedido
                                </p>
                            </div>
                            <div class="col-auto d-middle">
                                <span class="text-general f-600 f-20 mr-2">
                                    {{ cedis.franjas.parametros != null ? cedis.franjas.parametros.franja_maximo_cedis : '-' }}
                                </span>
                                <p class="col f-14 text-general f-300">
                                    Días máximo de <br /> programación del pedido
                                </p>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div v-for="(data,key1) in cedis.franjas.cedis" :key="`key1-${key1}`" class="col-auto pl-0 d-middle mb-3 mr-4">
                                <span class="text-general f-600 f-16 mr-3">
                                    {{ data.dia }}
                                </span>
                                <template v-if="data.horarios.length > 0">
                                    <div class="col">
                                        <p v-for="(data1,key2) in data.horarios" :key="`key2-${key2}`" class="text-general f-16 f-300">
                                            {{ data1.inicio }} - {{ data1.fin }}
                                        </p>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col">
                                        -
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="pl-5 mb-4">
                        <div class="row mx 0 mt-3 align-items-center mb-5">
                            <p class="col-auto mr-5 px-0 f-17 f-500 text-general">
                                Horario de los {{ $config.vendedor }}
                            </p>
                            <div class="col-auto d-middle">
                                <span class="text-general f-600 f-20 mr-2">
                                    {{ cedis.franjas.parametros != null ? cedis.franjas.parametros.franja_programacion_tiendas : '-' }}
                                </span>
                                <p class="col f-14 text-general f-300">
                                    Horas mínimo de <br /> programación del pedido
                                </p>
                            </div>
                            <div class="col-auto d-middle">
                                <span class="text-general f-600 f-20 mr-2">
                                    {{ cedis.franjas.parametros != null ? cedis.franjas.parametros.franja_maximo_tiendas : '-' }}
                                </span>
                                <p class="col f-14 text-general f-300">
                                    Días máximo de <br /> programación del pedido
                                </p>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div v-for="(data,key3) in cedis.franjas.tiendas" :key="`key3-${key3}`" class="col-auto pl-0 d-middle mb-3 mr-4">
                                <span class="text-general f-600 f-16 mr-3">
                                    {{ data.dia }}
                                </span>
                                <template v-if="data.horarios.length > 0">
                                    <div class="col">
                                        <p v-for="(data1,key4) in data.horarios" :key="`key4-${key4}`" class="text-general f-16 f-300">
                                            {{ data1.inicio }} - {{ data1.fin }}
                                        </p>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="col">
                                        -
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="pl-5 mb-5">
                        <div class="row mx 0 mt-4 align-items-center mb-4">
                            <p class="col-auto mr-5 px-0 f-17 f-500 text-general">
                                El cedis no maneja entregas programadas
                            </p>
                        </div>
                    </div>
                </template>
            </div>
            <!-- Separador -->
            <div class="col-12 mx-0 my-2" />
            <div class="col-12 bg-white py-3 br-12">
                <div class="row mx-0">
                    <div class="col-auto f-18 text-general mr-3">
                        Administradores
                    </div>
                    <div class="col px-0">
                        <div class="row mx-0">
                            <div v-for="(admin,x) in cedis.administradores_cedis" :key="x" class="col-3 f-14">
                                <span class="text-general">{{ admin.nombre }}</span>
                                <span v-if="admin.multicedis === 1" class="ml-2 text-general f-600">MC</span>
                                <i v-if="admin.estado == 0" class="icon-ojo-cerrado" />
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Separador -->
            <div class="col-12 mx-0 my-2" />
            <!-- Separador -->
            <div class="col-12 mx-0 my-2" />
            <div class="col-12 bg-white py-3 br-12">
                <div class="row mx-0">
                    <div class="col-auto f-18 text-general mr-3">
                        Forma de pago
                    </div>
                    <div class="col px-0">
                        <div class="row mx-0">
                            <div class="col-auto px-4">
                                <span v-for="(data,index) in formaPagosCedis" :key="index" class="text-general ml-2">
                                    {{ data.nombre }}
                                    <span v-if="index+1 < formaPagosCedis.length">,</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Separador -->
            <div class="col-12 mx-0 my-2" />
            <div class="col-12 bg-white py-3 br-12">
                <div class="row mx-0">
                    <div class="col-auto f-18 text-general mr-3">
                        Descuentos de lista<br /> por default
                    </div>
                    <div class="col px-0">
                        <div class="row mx-0">
                            <div class="col-auto">
                                <p v-if="cedis.parametros.descuento_lista === 1" class="text-general">
                                    Los nuevos {{$config.vendedor}}s del cedis pueden acogerse al beneficio de descuento de lista de manera automatica.
                                </p>
                                <p v-if="cedis.parametros.descuento_lista === 2" class="text-general">
                                    Los nuevos {{$config.vendedor}}s del cedis pueden acogerse al beneficio de descuento de lista de manera manual.
                                </p>
                                <p v-if="cedis.parametros.descuento_lista === 2" class="text-general">
                                    <b>Categoría:</b> {{ cedis.parametros.id_categoria_lista ? cedis.parametros.categoria_lista : 'No registra' }}
                                </p>
                                <p v-if="cedis.parametros.descuento_lista === 0" class="text-general">
                                    Los nuevos {{$config.vendedor}}s del cedis <b>NO</b> pueden acogerse al beneficio de descuento de lista.
                                </p>
                            </div>
                        </div>
                        <div class="row mx-0 mt-4">
                            <div class="col-auto text-general">
                                <p v-if="cedis.parametros.descuento_lista_clientes === 1">
                                    Los clientes de los {{$config.vendedor}}s nuevos del cedis pueden acogerse al beneficio de descuento de lista
                                </p>
                                <p v-if="cedis.parametros.descuento_lista_clientes === 0">
                                    Los clientes de los {{$config.vendedor}}s nuevos del cedis <b>NO</b> pueden acogerse al beneficio de descuento de lista
                                </p>
                            </div>
                        </div>
                        <div class="row mx-0 mt-4">
                            <div class="col-auto d-middle text-general">
                                <span class="f-35 f-600">{{ cedis.parametros.tiendas_descuento }}</span>
                                <span class="mx-3">{{$config.vendedor}}s con<br /> descuento de lista</span>
                            </div>
                            <div class="col-auto d-middle text-general">
                                <span class="f-35 f-600">{{ cedis.parametros.clientes_descuento }}</span>
                                <span class="mx-3">Clientes con<br /> descuento de lista</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Separador -->
            <div class="col-12 mx-0 my-2" />
            <!-- Separador -->
            <div class="col-12 mx-0 my-2" />
            <!-- Separador -->
            <div class="col-12 bg-white py-3 br-12">
                <div class="row mx-0">
                    <div class="col-auto f-18 text-general mr-2">
                        Parámetros temporales
                    </div>
                    <div class="col-auto text-general br-10 f-14 border ml-5">
                        <div class="py-2">
                            <strong>{{ cedis.horas_autoconfirmacion }}</strong> Minutos límite<br />
                            para auto confirmación.
                            <el-tooltip content="Horas límite que tiene el cliente para aceptar los cambios realizados en el listado de los productos del pedido. Antes que se confirmen automáticamente." placement="bottom" effect="light">
                                <i class="icon-info-circled-alt f-18" />
                            </el-tooltip>
                        </div>
                    </div>
                    <div class="col-auto text-general br-10 f-14 border ml-3">
                        <div class="py-2">
                            <strong>{{ cedis.dias_reclamo }}</strong> día límite<br />
                            para solicitar un reclamo.
                            <el-tooltip :content="`Días límite que tiene el cliente para solicitar un reclamo al ${$config.vendedor} una vez recibido el pedido.`" placement="bottom" effect="light">
                                <i class="icon-info-circled-alt f-18" />
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Separador -->
            <div class="col-12 mx-0 my-2" />
            <!-- Separador -->
            <div class="col-12 p-0 px-0 bg-white br-12 py-4 px-3 cr-pointer">
                <p class="mb-2">
                    <i class="icon-map-marker-outline f-20 text-general" />
                    <span class="text-general f-15">{{ cedis.direccion_completa }}</span>
                </p>
                <mapa ref="mapaPeq" height-mapa="400px" :coordenadas="coordenadas" icono="cedis-point-map.svg" />
            </div>
        </div>
        <!-- Partials -->
        <modalEliminar ref="modalEliminar" titulo="Eliminar cedis" mensaje="¿Realmente desea eliminar este cedis? Esta acción no puede ser revertida." @eliminar="delete_cedis" />
        <modal-editar-cedis ref="editarCedis" @listar="info_cedis" />
        <modal-ver-inventario ref="modalVerInventario" />
        <modal-usar-catalogo ref="modalUsarCatalogo" :tipo-titulo="tituloCategoria" />
    </section>
</template>

<script>
import Cedis from "../../../services/cedis/index";
import { mapGetters } from 'vuex'
export default {
    components: {
        modalEditarCedis: () => import('../partials/modalEditarCedis'),
        modalVerInventario: () => import('../partials/modalVerInventario'),
        modalUsarCatalogo: () => import('../partials/modaUsarCatalogo')
    },
    data(){
        return{
            coordenadas: {
                lat: 0,
                lng: 0
            },
            tituloCategoria: 1,
            tituloInventario: 1,
            cedis:{
                id: null,
                estado: null,
                cedis_inventario: false,
                nombre: null,
                descripcion: null,
                foto: null,
                direccion: null,
                impuestos:null,
                operacion_logistica:null,
                telefono: null,
                direccion_completa: null,
                id_cedis_stock: null,
                id_cedis_catalogo: null,
                longitud: null,
                latitud: null,
                stock_padre: null,
                catalogo_padre: null,
                moneda: {
                    id: null,
                    nombre: null,
                    sigla: null,
                },
                responsable: {
                    id: null,
                    nombre: null,
                    imagen: null
                },
                valores: {
                    productos: null,
                    unidades: null,
                    valores: null
                },
                hijosInventario: [],
                hermanosInventario: [],
                padreInventario: {id: null, nombre: null},
                hijosCatalogo: [],
                hermanosCatalogo: [],
                padreCatalogo: {id: null, nombre: null},
                administradores_cedis: [],
                horas_autoconfirmacion: null,
                dias_reclamo: null,
                formaPagos: [],
                franjas:{
                    cedis:[],
                    parametros:{},
                    tiendas:[]
                },
                parametros: {
                    id: null,
                    descuento_lista: null,
                    descuento_lista_clientes: null,
                    id_categoria_lista: null,
                    categoria_lista: null
                }
            },
            formaPagosCedis: [],
			editCode: false
        }
    },
    computed: {
        ...mapGetters({
            moneda:'cedis/moneda',
            id_moneda:'cedis/id_moneda',
        })
    },
    mounted(){
        this.info_cedis()
    },
    methods: {
        toggle(){
            this.info_cedis()
            this.$refs.modalInformacionCedis.toggle();
        },
        editarCedis(){
            let model = {}
            model.id = this.cedis.id
            model.estado = this.cedis.estado
            model.cedis_inventario = this.cedis.cedis_inventario
            model.cedis_catalogo = this.cedis.cedis_catalogo
            model.nombre = this.cedis.nombre
            model.direccion = this.cedis.direccion
            model.impuestos = this.cedis.impuestos
            model.operacion_logistica = this.cedis.operacion_logistica
            model.stock_agotado = this.cedis.stock_agotado
            model.telefono = this.cedis.telefono
            model.idm_moneda = this.cedis.idm_moneda
            model.idm_ciudad = this.cedis.idm_ciudad
            model.idm_estado = this.cedis.idm_estado
            model.id_cedis_stock = this.cedis.id_cedis_stock
            model.id_cedis_catalogo = this.cedis.id_cedis_catalogo
            model.idm_pais = this.cedis.idm_pais
            model.descripcion = this.cedis.descripcion
            model.latitud = this.cedis.latitud
            model.longitud = this.cedis.longitud
            model.id_user = this.cedis.id_user
            model.foto = this.cedis.foto
            model.imagen = this.cedis.imagen
            model.administradores_cedis = this.administradores_cedis;
            model.gaming  = this.cedis.gaming
            model.horas_autoconfirmacion = this.cedis.horas_autoconfirmacion
            model.dias_reclamo = this.cedis.dias_reclamo;
            model.formaPagos = this.cedis.formaPagos;

            this.$router.push({name: 'admin.cedis.editar-cedis', params: {id_cedis: model.id, data_cedis: model}})
        },
        eliminar(){
            this.$refs.modalEliminar.toggle()
            this.$refs.modalInformacionCedis.toggle();
        },
        async delete_cedis(){
            try {
                const {data} = await Cedis.delete_cedis(this.$route.params.id_cedis)
                this.notificacion('', data.mensaje, 'success')
                this.$refs.modalEliminar.toggle()
                this.$router.push({name:'admin.cedis'})

            } catch (e){
                this.error_catch(e)
            }
        },
        async info_cedis(){
            try {
                let params = {
                    id_moneda: this.id_moneda,
                    id_cedis: this.$route.params.id_cedis,
                }
                const {data} = await Cedis.info_cedis(params)
                this.cedis = data
                this.coordenadas.lat = +this.cedis.latitud
                this.coordenadas.lng = +this.cedis.longitud
                this.formaPagosCedis = data.formaPagos
                this.$refs.mapaPeq.setPosition()

            } catch (e){
                this.error_catch(e)
            }
        },
        verInventario(){
            if(this.cedis.stock_padre && this.cedis.hijosInventario.length == 0) return
            this.tituloInventario = this.cedis.id_cedis_stock ? 1 : 2;
            this.$refs.modalVerInventario.toggle(
                {
                    padre: this.cedis.padreInventario,
                    hijos: this.cedis.hijosInventario,
                    hermanos: this.cedis.hermanosInventario,
                    esPadre: this.cedis.stock_padre
                }
            )
        },
        usarCatalogo(){
            console.log(this.cedis.catalogo_padre && this.cedis.hijosCatalogo.length == 0);
            if(this.cedis.catalogo_padre && this.cedis.hijosCatalogo.length == 0) return
            this.tituloCategoria = this.cedis.id_cedis_catalogo ? 1 : 2
            this.$refs.modalUsarCatalogo.toggle(
                {
                    padre: this.cedis.padreCatalogo,
                    hijos: this.cedis.hijosCatalogo,
                    hermanos: this.cedis.hermanosCatalogo,
                    esPadre: this.cedis.catalogo_padre
                }
            )
        },
		async editCodeAlmacen(){
			try {
				const payload = {
					column: 'codigo_almacen',
					value: this.cedis.codigo_almacen,
					id: this.$route.params.id_cedis
				}
				const { data } = await Cedis.editCampoCedis(payload)
				this.editCode = false;
				this.notificacion('', data.mensaje, 'success')
			} catch (error) {
				return this.error_catch(error)
			}
		}
    },

}
</script>
<style lang="scss" scoped>
.br-12{ border-radius: 12px;}
.hover_general{
    background: #fff;
    color: var(--gr-r-general);
    &:hover{
        background: var(--gr-r-general);
        color: #fff;
    }
}
.hover_red{
    background: #fff;
    color: var(--gr-r-red);
    &:hover{
        background: var(--gr-r-red);
        color: #fff;
    }
}
.btn-cedis-stock{
    background-image: linear-gradient(to bottom, #FFFFFF, #F3F5F7);
    border-radius: 8px;
}

.card{
    top: 990px;
    left: 409px;
    width: 211px;
    height: 48px;
    /* UI Properties */
    background: var(---ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DFE4E8;
    border-radius: 8px;
    opacity: 1;
}
</style>
