<template>
    <section class="mx-4">
        <!-- <modal ref="modalCrearCedis" titulo="Editar Cedis" tamano="modal-lg" icon="cedis" @guardar="editar_cedis"> -->
        <div class="row mx-0 align-items-center my-3">
            <div class="back rounded-circle bg-general3 text-white cr-pointer d-flex a-center j-center" @click="$router.back()">
                <i class="icon-left f-15" />
            </div>
            <div class="col-auto f-18 f-600 text-general">
                Editar centro de distribución
            </div>
            <div class="bg-general text-white px-5 br-8 ml-auto cr-pointer d-flex align-items-center" style="height:32px;" @click="editar_cedis">
                Guardar
            </div>
        </div>
        <ValidationObserver ref="validacion" class="custom-scroll overflow-auto" tag="div" style="height:calc(100vh - 123px)">
            <div class="bg-white br-12 py-3 px-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Datos
                    </div>
                </div>
                <div class="row mx-0 mt-3">
                    <div class="col-auto px-5" />
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col">
                                <div class="row mx-0">
                                    <div style="width:18px;height:19px;" />
                                    <ValidationProvider v-slot="{errors}" tag="div" rules="required|max:30" name="nombre cedis" class="col-8">
                                        <label class="pl-3 text-general f-14"> Nombre del Cedis </label>
                                        <el-input v-model="model.nombre" class="w-100" size="small" maxlength="30" show-word-limit placeholder="Nombre" />
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row mx-0">
                                    <div style="width:18px;height:19px;" />
                                    <ValidationProvider v-slot="{errors}" tag="div" rules="required" name="moneda" class="col-8">
                                        <label class="pl-3 text-general f-14"> Moneda </label>
                                        <el-select v-model="model.idm_moneda" size="small" placeholder="Moneda" class="w-100">
                                            <el-option
                                            v-for="item in monedas"
                                            :key="item.id"
                                            :label="item.nombre"
                                            :value="item.id"
                                            />
                                        </el-select>
                                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <!-- Separador -->
                        <div class="row mx-0 py-3" />
                        <!-- Separador -->
                        <div class="row mx-0">
                            <div class="col">
                                <div class="row mx-0">
                                    <ValidationProvider vid="inventario" tag="div" rules="" class="col-12 d-flex">
                                        <el-checkbox v-model="model.cedis_inventario" class="check-dark">
                                            Almacenar inventario en CEDIS diferente
                                        </el-checkbox>
                                    </ValidationProvider>
                                    <div style="width:18px;height:19px;" />
                                    <div v-if="model.cedis_inventario" class="col-8 mt-3 pr-3">
                                        <ValidationProvider v-slot="{errors}" rules="required_if:inventario,true" name="cedis" tag="div">
                                            <label class="pl-3 text-general f-14"> Cedis </label>
                                            <el-select v-model="model.id_cedis_stock" size="small" placeholder="Cedis" class="w-100">
                                                <el-option
                                                v-for="item in cedis"
                                                :key="item.id"
                                                :label="item.nombre"
                                                :value="item.id"
                                                />
                                            </el-select>
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row mx-0">
                                    <div class="col-12 px-0">
                                        <ValidationProvider vid="catalogo">
                                            <el-checkbox v-model="model.cedis_catalogo" class="check-dark">
                                                Usar catálogo de CEDIS diferente
                                            </el-checkbox>
                                        </ValidationProvider>
                                    </div>
                                    <div style="width:18px;height:19px;" />
                                    <div v-if="model.cedis_catalogo" class="col-8 px-2 pr-3 mt-3">
                                        <ValidationProvider v-slot="{errors}" rules="required_if:catalogo,true" name="cedis">
                                            <label class="pl-3 text-general f-14"> Cedis </label>
                                            <el-select v-model="model.id_cedis_catalogo" size="small" placeholder="Cedis" class="w-100">
                                                <el-option
                                                v-for="item in cedis"
                                                :key="item.id"
                                                :label="item.nombre"
                                                :value="item.id"
                                                />
                                            </el-select>
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Separador -->
                        <div class="row mx-0 py-3" />
                        <!-- Separador -->
                        <div class="row mx-0">
                            <div class="col d-flex">
                                <div style="width:18px;height:19px;" />
                                <div class="col-8">
                                    <p class="ml-2 text-general f-14">Impuestos</p>
                                    <el-select v-model="model.impuestos" size="small" class="w-100">
                                        <el-option label="Sin Impuestos" :value="0" />
                                        <el-option label="Valores +IVA" :value="1" />
                                        <el-option label="IVA incluido" :value="2" />
                                        <el-option label="IVA incluido sin etiqueta" :value="3" />
                                    </el-select>
                                </div>
                            </div>
                            <div class="col">
                                <div class="row mx-0 align-items-center" style="height:53px;">
                                    <div class="col-auto px-2">
                                        <el-checkbox v-model="model.estado" :true-label="1" :false-label="0" class="check-dark">
                                            Activar Cedis
                                        </el-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Separador -->
                        <div class="row mx-0 py-3" />
                        <!-- Separador -->
                        <div class="row mx-0">
                            <div class="col d-flex">
                                <div style="width:18px;height:19px;" />
                                <ValidationProvider v-slot="{errors}" rules="required|max:20" name="teléfono" tag="div" class="col-8">
                                    <label class="pl-3 text-general f-14"> Teléfono </label>
                                    <el-input v-model="model.telefono" size="small" class="w-100" maxlength="20" show-word-limit placeholder="Teléfono" @change="buscarDireccion" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col">
                                <div class="row mx-0">
                                    <div style="width:18px;height:19px;" />
                                    <div class="col-8">
                                        <ValidationProvider v-slot="{errors}" rules="required" name="responsable">
                                            <label class="pl-3 text-general f-14"> Responsable </label>
                                            <el-select v-model="model.id_user" size="small" placeholder="Responsable" class="w-100">
                                                <el-option
                                                v-for="item in resposanbles"
                                                :key="item.id"
                                                :label="item.nombre"
                                                :value="item.id"
                                                />
                                            </el-select>
                                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 ml-4 mt-5">
                                <el-form ref="form" :model="model">
                                    <el-form-item class="text-general" label="Operador logístico">
                                        <el-radio-group v-model="model.operacion_logistica">
                                            <el-radio :label="1" class="radio-dark">
                                                Operador interno
                                            </el-radio>
                                            <el-radio :label="2" class="radio-dark">
                                                Operador externo
                                            </el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-form>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-12 ml-4 mt-5">
                                <ValidationProvider v-slot="{errors}" rules="required" name="stock_agotado">
                                    <el-form ref="form" :model="model">
                                        <el-form-item class="text-general" label="Productos que no se pueden vender sin stock">
                                            <el-radio-group v-model="model.stock_agotado">
                                                <el-radio :label="1" class="radio-dark">
                                                    Con productos disponibles
                                                </el-radio>
                                                <el-radio :label="2" class="radio-dark">
                                                    Productos reservados
                                                </el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-form>
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 px-0">
                        <div class="row mx-0 mb-3">
                            <slim-cropper ref="foto_cedis" :options="slimOptions" class="border" style="height:150px;background:#F8F9FF;width:150px;border-radius:12px;" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white br-12 py-4 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Gaming
                    </div>
                </div>
                <div class="row mx-0 mt-3 align-items-center">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto px-4" />
                    <div class="col-auto text-general">
                        Usuarios que tendrán los beneficios de gaming
                    </div>
                    <div class="col-auto d-middle">
                        <input v-model="model.gaming" :value="0" type="radio" class="option-input radio black" />
                        <span class="ml-14">
                            Ninguno
                        </span>
                    </div>
                    <div class="col-auto d-middle">
                        <input v-model="model.gaming" :value="1" type="radio" class="option-input radio black" />
                        <span class="ml-14">
                            {{ $config.vendedor }}
                        </span>
                    </div>
                    <div class="col-auto d-middle">
                        <input v-model="model.gaming" :value="2" type="radio" class="option-input radio black" />
                        <span class="ml-14">
                            Cliente
                        </span>
                    </div>
                    <div class="col-auto d-middle">
                        <input v-model="model.gaming" :value="3" type="radio" class="option-input radio black" />
                        <span class="ml-14">
                            Ambos
                        </span>
                    </div>
                </div>
            </div>
            <div class="bg-white br-12 py-3 pb-4 px-3 mt-3" :class="{'disabled-div':!$can('multicheck_admin_cedi')}">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Administradores
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col">
                        <div class="row mx-0">
                            <div v-for="(admin,x) in administradores" :key="x" class="col-4 mb-1">
                                <el-checkbox v-model="admin.adminCedi" :true-label="1" :false-label="0" class="text-general check-dark" @change="agregarAdminUserCedi($event,admin.id)">
                                    {{ admin.nombre }} <span v-if="admin.multicedis === 1" class="ml-2">MC</span>
                                    <el-tooltip content="Inactivo" placement="bottom" effect="light">
                                        <i v-if="admin.estado == 0" class="icon-ojo-cerrado" />
                                    </el-tooltip>
                                </el-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white br-12 py-3 pb-4 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Formas de pago
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col">
                        <div class="row mx-0">
                            <div v-for="(fp,index) in formasPagos" :key="index" class="col-4 mb-2">
                                <el-checkbox v-model="fp.aplica" :true-label="1" :false-label="0" class="text-general check-dark" @change="changeFormaPago($event, fp)">
                                    {{ fp.nombre }}
                                </el-checkbox>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-white br-12 py-3 pb-4 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Parámetros temporales
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col-auto">
                                <p class="text-general f-14 ml-2">Minutos auto confirmación</p>
                                <ValidationProvider v-slot="{errors}" rules="required|numeric" tag="div" class="col px-0" name="Horas auto confirmación">
                                    <el-input v-model="model.horas_autoconfirmacion" size="small" class="w-100" placeholder="Número de horas" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                                <el-tooltip content="Horas límite que tiene el cliente para aceptar los cambios realizados en el listado de los productos del pedido. Antes que se confirmen automáticamente." placement="bottom" effect="light">
                                    <i class="icon-info-circled-alt f-18 text-general" />
                                </el-tooltip>
                            </div>
                            <div class="col-auto">
                                <p class="text-general f-14 ml-2">Días de reclamo</p>
                                <ValidationProvider v-slot="{errors}" rules="required|numeric" tag="div" class="col px-0" name="Días de reclamo">
                                    <el-input v-model="model.dias_reclamo" size="small" class="w-100" placeholder="Número de días" />
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                                <el-tooltip :content="`Días límite que tiene el cliente para solicitar un reclamo al ${$config.vendedor} una vez recibido el pedido.`" placement="bottom" effect="light">
                                    <i class="icon-info-circled-alt f-18 text-general" />
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--
            <div class="bg-white br-12 py-3 pb-4 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Descuento de lista por default
                    </div>
                </div>
                <div class="row mx-0 mt-4">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col">
                        <div class="row mx-0">
                            <div class="col-auto">
                                <p class="text-general f-17 ml-2">Los nuevos Vendedores del cedis pueden acogerse al beneficio de descuento de lista.</p>
                            </div>
                        </div>
                        <div class="row mx-2 mt-3">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuento_lista" :value="1" type="radio" class="option-input radio black" @change="changeParametrosCedis('descuento_lista', model.descuento_lista)" />
                                <span class="ml-14 f-15">
                                    De manera automatica
                                </span>
                            </div>
                        </div>
                        <div class="row mx-2 mt-3">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuento_lista" :value="2" type="radio" class="option-input radio black" @change="changeParametrosCedis('descuento_lista', model.descuento_lista)" />
                                <span class="ml-14 f-15">
                                    De manera manual
                                </span>
                            </div>
                        </div>

                        <div v-if="model.descuento_lista === 2" class="row mx-5 mt-2">
                            <div class="col-auto">
                                <p class="f-12 text-general ml-2">Clasificación</p>
                                <el-select v-model="model.id_categoria_lista" size="small" placeholder="Seleccionar" @change="changeParametrosCedis('id_categoria_lista', model.id_categoria_lista)">
                                    <el-option
                                    v-for="item in listaClasificacion"
                                    :key="item.id"
                                    :label="item.nombre"
                                    :value="item.id"
                                    />
                                </el-select>
                                <div v-if="model.descuento_lista && !model.id_categoria_lista">
                                    <span class="text-danger w-100 f-11">
                                        Complete la selección escogiendo una clasificación
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-2 mt-3">
                            <div class="col-auto d-middle text-general">
                                <input v-model="model.descuento_lista" :value="0" type="radio" class="option-input radio black" @change="changeParametrosCedis('descuento_lista', model.descuento_lista)" />
                                <span class="ml-14 f-15">
                                    No acogerlos al beneficio.
                                </span>
                            </div>
                        </div>
                        <div class="row mx-2 mt-3">
                            <div class="col-auto d-middle text-general">
                                <el-checkbox v-model="model.descuento_lista_clientes" :true-label="1" :false-label="0" class="check-green" @change="changeParametrosCedis('descuento_lista_clientes', model.descuento_lista_clientes)" />
                                <span class="ml-3 f-15">Los clientes de los Vendedores nuevos del cedis pueden acogerse al beneficio de descuento de lista</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            -->
            <div class="bg-white br-12 py-3 pb-4 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-18 f-600">
                        Horario laboral
                    </div>
                </div>
                <horario-laboral class="pl-5 ml-4" />
            </div>
            <div class="bg-white br-12 pt-4 pb-3 pb-4 px-3 mt-3">
                <div class="row mx-0">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto text-general f-17 f-500">
                        Ubicación
                    </div>
                </div>
                <div class="row mx-0 mt-3">
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-auto" />
                    <div class="col-10">
                        <div class="row mx-0 mt-2">
                            <div class="col-4 px-1">
                                <ValidationProvider v-slot="{errors}" rules="required" name="país">
                                    <p class="ml-2 text-general f-14 pl-2">País</p>
                                    <el-select v-model="model.idm_pais" size="small" class="w-100" filterable @change="consultarEstados()">
                                        <el-option
                                        v-for="item in paises"
                                        :key="item.id"
                                        :label="item.nombre"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-4 px-2">
                                <ValidationProvider v-slot="{errors}" rules="required" name="estado">
                                    <p class="ml-2 text-general f-14 pl-2">Estado</p>
                                    <el-select v-model="model.idm_estado" size="small" class="w-100" filterable @change="consultarCiudades()">
                                        <el-option
                                        v-for="item in estados"
                                        :key="item.id"
                                        :label="item.nombre"
                                        :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                            <div class="col-4 px-1">
                                <ValidationProvider v-slot="{errors}" rules="required" name="ciudad">
                                    <p class="ml-2 text-general f-14 pl-2">Ciudad</p>
                                    <div class="row mx-0 align-items-center">
                                        <el-select v-model="model.idm_ciudad" size="small" class="col px-0" filterable>
                                            <el-option
                                            v-for="item in ciudades"
                                            :key="item.id"
                                            :label="item.nombre"
                                            :value="item.id"
                                            />
                                        </el-select>
                                    </div>
                                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row mx-0 my-3">
                            <ValidationProvider v-slot="{errors}" rules="required|max:120" tag="div" class="col px-0" name="dirección">
                                <label class="pl-3 text-general f-14"> Dirección </label>
                                <el-input v-model="model.direccion" size="small" class="w-100" maxlength="120" show-word-limit placeholder="Dirección" @change="buscarDireccion" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </div>
                        <div class="row mx-0 my-3">
                            <div class="col 12 px-0">
                                <mapa ref="mapaPeq" class="br-12" :coordenadas="coordenadas" icono="cedis-point-map.svg" :buscar="getDireccionCon" @actualizar="actualizarCoordenadas" />
                                <p class="f-13 mt-2 text-gris2 cr-pointer" @click="abrirModalMapa()">
                                    Haga click aquí, para seleccionar la ubicación de su tienda
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ValidationObserver>
        <mapa-google-modal
        ref="modalMapaGoogle"
        busqueda
        postal
        icono-central="/img/icons/cedis-point-map.svg"
        :buscar="getDireccionCon"
        :coordenadas-entrada="coordenadas"
        @actualizar="actualizarCoordenadasCiudad"
        />
        <!-- <div class="custom-scroll overflow-auto" > -->
        <!-- </div> -->
    </section>
</template>
<script>
import { mapGetters } from 'vuex'
import Localizaciones from "~/services/proyectos/localizaciones";
import CedisAll from "~/services/cedis";
import Cedis from "~/services/cedis/index"

export default {
    components: {
        horarioLaboral: () => import('./components/horarioLaboralEditar')
    },
    data(){
        return{
            disabled: true,
            check: '',
            paises: [],
            estados: [],
            ciudades: [],
            slimOptions: {
                ratio: '1:1',
                label:'Subir imagen',
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok',
                buttonUploadLabel: 'Ok',
            },
            model: {
                id: null,
                estado: true,
                cedis_inventario: false,
                cedis_catalogo: false,
                nombre: '',
                direccion: '',
                impuestos:0,
                telefono: '',
                idm_moneda: null,
                descripcion: '',
                latitud: '',
                longitud: '',
                id_user: null,
                idm_pais: null,
                idm_estado: null,
                id_cedis_stock: null,
                id_cedis_catalogo: null,
                idm_ciudad: null,
                operacion_logistica: null,
                stock_agotado: '1',
                gaming: null,
                horas_autoconfirmacion: null,
                dias_reclamo: null,
                descuento_lista: 0,
                descuento_lista_clientes: 2,
                id_categoria_lista: null,
            },
            coordenadas: {
                lat: 7.080734,
                lng: -73.147827,
            },
            resposanbles: [],
            administradores: [],
            formasPagos:[],
            listaClasificacion: [],
        }
    },
    computed: {
        ...mapGetters({
            monedas:'cedis/monedas',
        }),
        getDireccionCon(){
            const { ciudad = '' } = this.ciudades.find(x => x.id === this.model.idm_ciudad) || {};
            const { nombre: pais = '' } = this.paises.find(x => x.id === this.model.idm_pais) || {};
            return [this.model.direccion, ciudad, pais].filter(el => el).join(',')
        },
        idCedis(){
            return  Number(this.$route.params.id_cedis)
        }
    },
    async created(){
        await this.loadInfoCedis()
        this.setUbicacion()
    },
    methods: {
        async loadInfoCedis(){
            try {
                const {data} = await Cedis.getInfoCedis(this.idCedis)
                this.model = data.cedi
                this.administradores = data.administradores;
                this.resposanbles = data.responsables
                this.cedis = data.arrayCedis
                this.formasPagos = data.formasPago
                this.listaClasificacion = data.clasificaciones;

                if (this.model.foto !== null){
                    this.$refs.foto_cedis.set_image(this.model.imagen)
                } else {
                    this.$refs.foto_cedis.instanciaCrop.remove()
                }

            } catch (error){
                this.error_catch(error)
            }
        },
        async setUbicacion(){
            await this.consultarPaises()
            this.coordenadas.lat = +this.model.latitud
            this.coordenadas.lng = +this.model.longitud
            await  this.consultarEstados(true)
            await this.consultarCiudades(true)
            this.$refs.mapaPeq.setPosition()


        },
        abrirModalMapa(){
            this.$refs.modalMapaGoogle.toggle()
        },
        actualizarCoordenadasCiudad({ latitud, longitud }){
            this.model.latitud = latitud
            this.model.longitud = longitud
            this.coordenadas.lat = +this.model.latitud
            this.coordenadas.lng = +this.model.longitud
            this.$refs.mapaPeq.setPosition()
        },
        async editar_cedis(){
            try {

                const valid = await this.$refs.validacion.validate()
                if(!valid){
                    this.notificacion('Mensaje', 'campos obligatorios', 'warning')
                    return false
                }
                const archivo = this.$refs.foto_cedis.instanciaCrop.dataBase64.output
                this.model.foto = archivo.image
                const {data} = await Cedis.editar_cedis(this.model)
                this.notificacion('Mensaje', 'Cedis editado exitosamente', 'success')
                this.$store.dispatch('cedis/action_get_cedis')
                this.$emit('listar')

                this.$router.back();
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarPaises(){
            try {
                const {data} = await Localizaciones.consultarPaises(this.$usuario.proyecto.id)
                this.paises = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarEstados(init = false){
            try {
                if(!init){
                    console.log('aca entrro');
                    this.model.idm_estado = null
                    this.model.idm_ciudad = null
                }
                this.estados = []
                this.ciudades = []
                const {data} = await Localizaciones.consultarEstados({
                    proyecto: this.$usuario.proyecto.id,
                    pais: this.model.idm_pais
                })

                this.estados = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        async consultarCiudades(init = false){
            try {
                if(!init){
                    this.model.idm_ciudad = null
                }
                this.ciudades = []
                const {data} = await Localizaciones.consultarCiudades({
                    proyecto: this.$usuario.proyecto.id,
                    estado: this.model.idm_estado
                })
                this.ciudades = data.data
            } catch (e){
                this.error_catch(e)
            }
        },
        actualizarCoordenadas({ latitud, longitud }){
            this.model.latitud = latitud
            this.model.longitud = longitud
        },
        buscarDireccion(){
            this.$refs.mapaPeq.accionBuscar();
        },
        async postAgregarUserCedi(id){
            try {
                const params = {
                    id_user: id,
                    id_cedi: this.idCedis,
                };
                const {data} = await CedisAll.postAgregarUserCedi(params)
                this.notificacion('Mensaje',"Administrador agregado", 'success')
            } catch (e){
                this.error_catch(e)
            }
        },

        async deleteRemoverUserCedi(id_user){
            try {
                const {data} = await CedisAll.deleteRemoverUserCedi(this.idCedis,id_user)
                this.notificacion('Mensaje', "Administrador removido", 'success')
            } catch (e){
                this.error_catch(e)
            }
        },

        agregarAdminUserCedi(event,id){
            if (event){
                this.postAgregarUserCedi(id);
            } else {
                this.deleteRemoverUserCedi(id);
            }
        },

        async changeFormaPago(event, item){
            try {
                let accion = 0;
                if(event){
                    accion = 1; // Se añade la forma de pago al cedis
                } else {
                    accion = 2; // Se elimina la forma de pago al cedis
                }
                if(accion == 0)return;

                let idFormaPago = null
                if(item.id){
                    idFormaPago = item.id
                }

                let column = null
                if(item.nombre === "Efectivo"){
                    column = "pago_efectivo"
                }
                if(item.nombre === "Crédito"){
                    column = "pago_credito"
                }

                const model = {
                    idCedis: this.idCedis,
                    idFormaPago: idFormaPago,
                    column: column,
                    accion: accion
                };
                const {data} = await CedisAll.changeFormaPago(model)
                this.notificacion('Mensaje', 'Metodos de pago actualizados', 'success')
            } catch (e){
                this.error_catch(e)
            }
        },

        async changeParametrosCedis(column, value){
            try {
                const payload = {
                    column: column,
                    value: value
                };
                const {data} = await CedisAll.changeParametrosCedis(this.idCedis, payload);
                this.notificacion('Mensaje', data.mensaje, data.tipo);

                if(column === 'descuento_lista' && parseInt(value) != 2){
                    this.model.id_categoria_lista = null;
                }
            } catch(e){
                this.error_catch(e);
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.dia{
    width: 93px;
    height: 32px;
    border: 1px solid #DFE4E8;
    border-radius: 8px;
    &.activo{
        background-color: var(--text-general) !important;
        color: #ffffff !important;
        border: none !important;
    }
}
</style>
